import { useState } from "react";
import "./App.css";

function App() {
  const [Count, setCount] = useState(1);
  const [Char, setChar] = useState("X");
  const [Winner, setWinner] = useState("");
  const [matrix, setMatrix] = useState([
    ["", "", ""],
    ["", "", ""],
    ["", "", ""],
  ]);

  const getBGclass = (val) => {
    console.log('classClick');
    if (val === "X") return "yellow";
    if (val === "O") return "orange";

    return "";
  };

  const handleClick = (r, c) => {
    console.log('onclick');
    if(matrix[r][c]) return;
    let newMatrix = [...matrix];
    newMatrix[r][c] = Char;
    setMatrix(newMatrix);
    setChar(Char === "X" ? "O" : "X");
    setCount(Count + 1)
    console.log(Count);
    checkWinner();
  };

  const checkWinner = () => {
    // check row
    if(matrix[0][0] && matrix[0][0] === matrix[0][1] && matrix[0][0] === matrix[0][2]){
      setWinner(matrix[0][0] + ' is a winner');
    }
    if(matrix[1][0] && matrix[1][0] === matrix[1][1] && matrix[1][0] === matrix[1][2]){
      setWinner(matrix[0][0] + ' is a winner');
    }
    if(matrix[2][0] && matrix[2][0] === matrix[2][1] && matrix[2][0] === matrix[2][2]){
      setWinner(matrix[0][0] + ' is a winner');
    }
    if(Count === 9){
      setWinner('Match has been Draw')
    }
  }
  return (
    <div className="app">
      <div className="header alignCenter">Tic TAc Toe</div>
      <div className="board alignCenter">
        {!Winner && <p> {Char} turn now</p>}

        <div className="gameBoard">
          
          {Winner || matrix.map((row, rIndex) => (
            <div key={rIndex} className="row">
              {row.map((cell, cIndex) => (
                <div
                  key={cIndex}
                  onClick={() => handleClick(rIndex, cIndex)}
                  className={`cell alignCenter ${getBGclass(
                    matrix[rIndex][cIndex]
                  )}`}
                >
                  {cell}
                </div>
              ))}
            </div>
          ))}
        </div>
        <button
          className="btn"
          onClick={() =>
          {
            setMatrix([
              ["", "", ""],
              ["", "", ""],
              ["", "", ""],
            ])
            setChar('X')
            setWinner('')
            setCount(1)
          }
          }
        >
          Reset
        </button>
      </div>
    </div>
  );
}

export default App;
